<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<cvrm-page-wave-background color="blue">
  <div class="home-page-container">
    <ng-container *ngIf="site$ | async as site">
      <ng-container [ngSwitch]="site">

        <ng-container *ngSwitchCase="Site.Default">
          <div class="page-wave-title">
            <h1 class="title" translate>TITLE</h1>
            <cvrm-divider padding="20"></cvrm-divider>
            <h3 translate>CALL_TO_ACTION</h3>
    
            <app-home-navigation [userType]="userType$ | async" [gracePeriod]="gracePeriod$ | async">
            </app-home-navigation>
    
            <section class="video">
              <div class="video__section">
                <img class="video__image" src="/assets/physician.png">
                <div class="video-btn" (click)="onOpenModal('/assets/clinician-video')">
                  <fa-icon icon="play"></fa-icon>
                  <span translate>CLINICIAN_VIDEO</span>
                </div>
              </div>
              <div class="video__section">
                <img class="video__image" src="/assets/patient.png">
                <div class="video-btn" (click)="onOpenModal('/assets/patient-video')">
                  <fa-icon icon="play"></fa-icon>
                  <span translate>PATIENT_VIDEO</span>
                </div>
              </div>
            </section>
    
          <cvrm-divider padding="20"></cvrm-divider>
          <div translate>SUBTITLE</div>
    
          <div>
              <ng-container *ngIf="isEnglishLang$ | async else inDutch">
                For more information, please visit us at 
                <a style="color: white;" href="https://www.sciencrew.com/c/6751?title=UPrevent" target="_blank">ScienCrew</a>. 
              </ng-container>
              <ng-template #inDutch>
                Bezoek 
                <a style="color: white;" href="https://www.sciencrew.com/c/6751?title=UPrevent" target="_blank">ScienCrew</a>
                voor meer informatie.
              </ng-template>
              
              <div>
                <a href="https://www.sciencrew.com/c/6751?title=UPrevent" target="_blank">
                  <img class="scien-crew" src="/assets/sciencrew.png">
                </a>
              </div>
            </div>
          </div>
    
          <cvrm-new-calculators-banner [lang]="lang$ | async"></cvrm-new-calculators-banner>
    
          <cvrm-ortec-banner [lang]="lang$ | async"></cvrm-ortec-banner>
        </ng-container>

        <ng-container *ngSwitchCase="Site.VtePredict">
          <div class="page-wave-title">
            <div class="page-wave-title">
              <h1 class="title" translate>CALCULATOR_OVERVIEW.VTE_PREDICT.NAME</h1>
              <h3 translate>CALCULATOR_OVERVIEW.VTE_PREDICT.TITLE</h3>
              <a routerLink="/calculators/vtePredict" class="btn-cta" translate>TO_CALCULATORS</a>
              <cvrm-vte-predict-banner></cvrm-vte-predict-banner>
            </div>
          </div>
        </ng-container> 
          
        <ng-container *ngSwitchCase="Site.LifeHf">
          <div class="page-wave-title">
            <div class="page-wave-title">
              <h1 class="title" translate>CALCULATOR_OVERVIEW.LIFE_HF.NAME</h1>
              <a routerLink="/calculators/lifeHf" class="btn-cta" translate>TO_CALCULATORS</a>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</cvrm-page-wave-background>
<div class="page-container">
  <div class="info-section">
    <div class="info-section__box">
      <div class="home-img">
        <div class="icon-img">
          <img src="/assets/bigdata.png">
        </div>
        <div class="home-img-title" translate>BIG_DATA_RESEARCH</div>
        <div class="home-img-text" translate>BIG_DATA_RESEARCH_BODY</div>
      </div>
    </div>



    <div class="info-section__box">
      <div class="home-img">
        <div class="icon-img">
          <img src="/assets/medication.png">
        </div>
        <div class="home-img-title" translate>THE_RIGHT_MEDICATION</div>
        <div class="home-img-text" translate>THE_RIGHT_MEDICATION_BODY</div>
      </div>
    </div>



    <div class="info-section__box">
      <div class="home-img">
        <div class="icon-img">
          <img src="/assets/empower.png">
        </div>
        <div class="home-img-title" translate>EMPOWER_YOUR_PATIENTS</div>
        <div class="home-img-text" translate>EMPOWER_YOUR_PATIENTS_BODY</div>
      </div>
    </div>

  </div>

  <div class="sponsor-logos">
    <div class="sponsor-logos__total-row">
      <div class="logo-img-container" *ngIf="(site$ | async) !== Site.VtePredict">
        <a [href]="getLink('UMC_UTRECHT_SPECIALISM')" target="_blank">
          <img class="logo-img" src="/assets/logo-vascularmedicine.png">
        </a>
      </div>
      <div class="logo-img-container" *ngIf="(site$ | async) !== Site.VtePredict">
        <a [href]="getLink('HARTSTICHTING')" target="_blank">
          <img class="logo-img" src="/assets/logo-hartstichting.png">
        </a>
      </div>
      <div class="logo-img-container">
        <a [href]="getLink('ZONMW')" target="_blank">
          <img class="logo-img" src="/assets/logo-zonmw.png">
        </a>
      </div>
    </div>
  </div>

</div>
<cvrm-footer-wave-background color="blue"></cvrm-footer-wave-background>