<div [hidden]="field?.props.hidden" class="group" [id]="id + 'Group'" [attr.field-key]="fieldKey" [attr.data-cy]="fieldKey">

  <label [attr.for]="id"  class="group__label"  [ngClass]="{'label-clickable': field.type === 'switch'}" >
    <span *ngIf="isShowDescription" [innerHTML]="description" class="description__tooltip" [attr.data-cy]="'descriptionTooltip'"></span>
    <span [translate]="wrapperLabel" [attr.data-cy]="field.key + 'Label'"></span>
    <span class="description" #icon (click)="toggleDescription()"  [attr.data-cy]="'descriptionIcon'">
      <fa-icon
        *ngIf="description"
        icon="info-circle"></fa-icon>
    </span>

    <span class="description" #icon (click)="toDescription()">
      <fa-icon *ngIf="descriptionLinkEnabled" icon="info-circle"></fa-icon>
    </span>
  </label>

  <div class="group__fields" [ngClass]="{prefilled: (isPrefillFeatureOn$ | async)}">
    <ng-template #fieldComponent style="display: flex"></ng-template>
  </div>
  
  <ng-container *ngIf="isPrefillFeatureOn$ | async">
    <label class="group__timestamp">
      <ng-container *ngIf="prefillInfo$ | async as prefillInfo">
        <span *ngIf="isShowInfo" [translate]="prefillInfo.toolTipText" class="info__tooltip"></span>
        <ng-container *ngIf="isOverriddenDataField$ | async; else timestamp">
          <button type="button" [disabled]="isReloadDisabled" [attr.data-cy]="prefillInfo.id + '-reload'" class="btn btn-sm source-btn" (click)="resetDataToSource()"><fa-icon icon="redo"></fa-icon></button>
        </ng-container>
  
        <ng-template #timestamp>
          <ng-container *ngIf="prefillInfo.prefilled; else notPrefilled">
            <span *ngIf="prefillInfo.date" (click)="toggleInfo()" [attr.data-cy]="prefillInfo.id + '-date'" class="info__date">
              {{ prefillInfo.date | date: 'yyyy-MM-dd' }}
            </span>
            <span *ngIf="!prefillInfo.date" (click)="toggleInfo()" [attr.data-cy]="prefillInfo.id + '-no-date'" class="info__timestamp">
              <span class="material-icons">assignment_turned_in</span>
            </span>
          </ng-container>        
          <ng-template #notPrefilled>
            <span class="info__timestamp" (click)="toggleInfo()" [attr.data-cy]="prefillInfo.id + '-no-data'">
              <span class="material-icons">content_paste_off</span>
            </span>
          </ng-template>
        </ng-template>
      </ng-container>
    </label>
  </ng-container>
</div>

<div class="message">
  <bol-callout *ngIf="message$ | async as message" [attr.data-cy]="field.key + 'Message'"
    [icon]="message.cssClassName" [ngClass]="'message-' + message.cssClassName" [type]="message.cssClassName"> {{ message.label | translate}}
  </bol-callout>
</div>
