import { Component, Input } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { environment } from 'environments/environment';
import { EnvironmentName } from 'environments/environment.config';
import { map } from 'rxjs';

export enum CEMark {
    DoNotShow = 'DoNotShow',
    BlackCEMark = 'BlackCEMark',
    WhiteCEMark = 'WhiteCEMark'
}

@Component({
    selector: 'cvrm-footer-wave-background',
    templateUrl: './footer-wave-background.component.html',
    styleUrls: ['./footer-wave-background.component.scss']
})
export class FooterWaveBackgroundComponent {
    @Input() public color: string;
    @Input() public ceMark: CEMark = CEMark.DoNotShow;

    public CEMark = CEMark;
    public appVersion$ = this.generalQuery.selectAppVersion();
    public isMdrCertified$ = this.generalQuery.isMdrCertified();
    public isProduction = environment.name === EnvironmentName.Production;
    public showPrivacyStatement$ = this.generalQuery.select().pipe(
        map(generalQuery => generalQuery.features.requiresAuthentication != null)
    );

    constructor(
        private readonly generalQuery: GeneralQuery
    ) { }
}
