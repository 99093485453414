<div [hidden]="field?.props.hidden" class="group">

  <label [attr.for]="id"  class="group__label"  [ngClass]="{'label-clickable': field.type === 'switch'}" >
    <span [innerHTML]="description" class="description__tooltip" [ngClass]="{active: isShowDescription}"></span>
    <span  [translate]="wrapperLabel"></span>
    <span class="description" *ngIf="description">
      <fa-icon icon="info-circle" (click)="toggleDescription()"></fa-icon>
    </span>
  </label>

  <div class="group__fields">
    <ng-template #fieldComponent style="display: flex"></ng-template>
  </div>  
</div>

<div class="message">
  <bol-callout *ngIf="message$ | async as message" [attr.data-cy]="field.key + 'Message'"
    [icon]="message.cssClassName" [ngClass]="'message-' + message.cssClassName" [type]="message.cssClassName"> {{ message.label | translate}}
  </bol-callout>
</div>
