import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { Expressions, FieldConfig } from '@app-features/calculators/calculator.model';
import { CalculatorQuery } from '@app-features/calculators/calculator.query';
import { CalculatorStore } from '@app-features/calculators/calculator.store';
import { PatientModel, Value } from '@app-features/calculators/patient-data/patient-data.model';
import { Site } from '@app-shared/models/site-configuration-enum';
import { ApiBaseUrl } from '@app-shared/services/url.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { catchError, first, Observable, tap } from 'rxjs';

export type ExportPatientData = Record<string, Value | { value: Value; unit: string }>;

export const LIFEHF_RISK_KEYS = [
    'lifeExpectancy', 
    'lifeExpectancySecondary'
];

export const RISK_KEYS = [
    'lifeExpectancy',
    'lifeExpectancySecondary',
    'twoYearRisk',
    'fiveYearRisk',
    'twoYearRiskSecondary',
    'fiveYearRiskSecondary',
    'tenYearRisk',
    'fiveYearRiskWithHeartFailure',
    'tenYearRiskWithHeartFailure',
    'mortalityRisk',
    'lifetimeRisk',
    'diseaseRisk',
    'fiveYearVTERecurrenceRisk',
    'fiveYearBleedingRisk',
    'oneYearVTERecurrenceRisk',
    'oneYearBleedingRisk',
];
const ALFANUMERIC_MATCH = /[a-zA-Z0-9]+|[^a-z]+/gi;

@Injectable({ providedIn: 'root' })
export class CalculatorService {
    private disableForm: boolean = false;    
    private site: string;

    constructor(
        private readonly http: HttpClient, 
        private readonly calculatorQuery: CalculatorQuery, 
        private readonly calculatorStore: CalculatorStore,
        private readonly generalQuery: GeneralQuery
    ) {       
        // Store the site configuration in a variable so we can use it synchronously later in the vtePredict hack. 
        // We will read the first value and keep using it. This works because the query is guaranteed in app.initializer.ts to have been done already.
        this.generalQuery.selectSite().pipe(first()).subscribe(site => { 
            this.site = site;
        });
    }

    public setActiveCalculator(calculatorName: string): void {
        if (this.calculatorQuery.getActiveId() !== calculatorName) {
            this.calculatorStore.update(null, { model: {} });
            this.calculatorStore.setActive(calculatorName);
        }
    }

    public setIntakeResult(model: PatientModel, hiddenFields: Array<{id: string; hide: boolean}>): void {
        this.calculatorStore.updateActive((active) => {
            return { ...active, model, hiddenFields };
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getTreatmentResult(treatmentModel: PatientModel): Observable<any> {
        const activeCalculator = this.calculatorQuery.getActive();
        this.calculatorStore.setLoading(true);
        this.calculatorStore.setError(null);

        const treatment = JSON.parse(JSON.stringify(treatmentModel.treatment));
        const model = { ...activeCalculator.model, treatment };

        this.calculatorStore.updateActive((active) => {
            return { ...active, model };
        });
        const postData = this.getPostData();

        return this.http.post(ApiBaseUrl('/api/RiskCalculation/calculate'), { ...postData, id: activeCalculator.id }).pipe(
            tap(() => this.calculatorStore.setLoading(false)),
            tap((result: any) => {
                this.calculatorStore.updateActive((active) => {
                    this.calculatorStore.setError(null);
                    const { imputations, normalRanges, version, ...risks } = result;
                    const defaultRisk = RISK_KEYS.filter((key) => Object.keys(risks).includes(key))[0];
                    const selectedGraph =
                            active.selectedGraph && risks[active.selectedGraph] ? 
                                active.selectedGraph : risks[defaultRisk] ? 
                                    defaultRisk : Object.keys(risks)[0];

                    return { ...active, imputations, normalRanges, version, risks, selectedGraph };
                });
            }),
            catchError((error: any) => {
                this.calculatorStore.setLoading(false);
                this.calculatorStore.setError(error);

                throw error;
            }),
        );
    }

    public getPostData(): ExportPatientData {
        const activeCalculator = this.calculatorQuery.getActive();
        
        return (activeCalculator.fields || [])
            .filter(field => field.type !== 'unitSelector')
            .map(f => {
                const categoryKey = `${f.category}${f.unitSelector || ''}`;

                const group = (activeCalculator.model[categoryKey] || {})[`${f.id}Group`];
                const field = group ? group[`${f.id}Field`] : null;
                const imputation = group ? group[`${f.id}Imputed`] as boolean : false;

                let value: Value;
                let unit: string;
                if (imputation != null && typeof imputation === 'boolean' && imputation) 
                {
                    value = 'Imputed';
                } else if (typeof field !== 'boolean') {
                    value = field ? field[f.id] : null;
                    unit = f.units && field ? field[`${f.id}Unit`] as string : null;
                }

                if (value === 'null' || value === '') {
                    value = null;
                }

                const result = unit ? { value, unit } : value;

                return { [f.id]: result };
            })
            .reduce((data, field) => {
                data = { ...data, ...field };

                return data;
            }, {});
    }

    public createTreatmentForm(fields: Array<FieldConfig>): Array<FormlyFieldConfig> {
        return this.createForm(
            fields,
            {
                unitSelector: 'button-select',
                metaSelector: 'button-select',
                boolean: 'switch',
                radio: 'button-select',
                integer: 'number',
                real: 'number',
                hidden: 'input',
            },
            {
                boolean: 'result-row-wrapper',
                radio: 'result-column-wrapper',
                integer: 'result-column-wrapper',
                real: 'result-column-wrapper',
                select: 'result-column-wrapper',
            }
        );
    }

    public createIntakeForm(fields: Array<FieldConfig>): Array<FormlyFieldConfig> {
        return this.createForm(fields, {
            unitSelector: 'button-select',
            metaSelector: 'button-select',
            boolean: 'switch',
            radio: 'button-radio-row',
            integer: 'number',
            real: 'number',
            select: 'button-select',
            hidden: 'input',
        });
    }

    public updateSelectedGraph(key: string): void {
        this.calculatorStore.updateSelectedGraph(key);
    }

    public toggleFormDisable(disable: boolean): void {
        this.disableForm = disable;
    }

    private createForm(
        fields: Array<FieldConfig>,
        typeMapping: Record<string, string>,
        wrapperMapping: Record<string, string> = {}): Array<FormlyFieldConfig> {

        const idMapping = fields.reduce<Record<string, string>>((mapping, field) => {
            mapping[field.id] = `formState.formModel.${field.category}${field.unitSelector || ''}${
                field.type === 'unitSelector' ? field.id : ''
            }?.${field.id}Group?.${field.id}Field.${field.id}`;

            if (field.imputable) {
                mapping[`${field.id}Imputed`] = `formState.formModel.${field.category}${field.unitSelector || ''}.${field.id}Group?.${
                    field.id
                }Imputed`;
            }
            if (field.units) {
                mapping[`${field.id}Unit`] = `formState.formModel.${field.category}${field.unitSelector || ''}.${field.id}Group?.${
                    field.id
                }Field.${field.id}Unit`;
            }

            return mapping;
        }, {});

        return fields
            .map((field) => ({ ...field }))
            .reduce<Array<FormlyFieldConfig>>((categories, field) => {
            const fieldIsUnitSelector = fields.some(
                (unitSelectorField) => unitSelectorField.unitSelector && unitSelectorField.unitSelector === field.id
            );

            field.isUnitSelector = fieldIsUnitSelector;

            const categoryKey = `${field.category}${fieldIsUnitSelector ? field.id : ''}${
                field.unitSelector ? field.unitSelector : ''
            }`;
            const fieldCategory: FormlyFieldConfig = categories.find((category) => category.key === categoryKey) || {
                key: categoryKey,
                fieldGroup: [],
            };

            field.isUnitSelector = fieldIsUnitSelector;
            const fieldGroup = this.createFieldGroup(field, idMapping, typeMapping, wrapperMapping);

            fieldCategory.fieldGroup.push(fieldGroup);

            if (categories.indexOf(fieldCategory) === -1) {
                categories.push(fieldCategory);
            }

            return categories;
        }, []);
    }

    private createFieldGroup(
        field: FieldConfig,
        idMapping: Record<string, string>,
        typeMapping: Record<string, string>,
        wrapperMapping: Record<string, string>): FormlyFieldConfig {

        const imputationAndInputFieldGroup = [];

        const inputField: FormlyFieldConfig = {
            key: field.id,
            type: typeMapping[field.type] || field.type,
            className: field.units ? 'col-sm-12 inline-left' : '',
            defaultValue: normalize(field.defaultValue, field.type),
            expressions: {
                'props.disabled': () => this.disableForm,
            },
        };

        if (field.imputable) {
            imputationAndInputFieldGroup.push({
                key: `${field.id}Imputed`,
                type: 'imputation-switch',
            });
            inputField.expressions['props.disabled'] = `formState.formModel.${field.category}${
                field.unitSelector || ''
            }.${field.id}Group?.${field.id}Imputed == true`;
        }        
        if (field.options) {
            inputField.props = {
                options: field.options
                    .map((option) => ({ ...option, value: option.value !== undefined ? option.value : null }))
                    .map((option) => ({ ...option, id: option.value })),
            };
            if (field.isUnitSelector) {
                inputField.defaultValue = field.options[0].value;
            }
        }
        if (field.required) {
            inputField.props = inputField.props || {};
            inputField.props.required = true;
        }
        if (field.disabled) { 
            inputField.props = inputField.props || {};
            inputField.props.disabled = true;
        }

        const inputFieldGroup: Array<FormlyFieldConfig> = [inputField];

        if (field.units) {
            const units = field.units.map((unit) => ({
                label: unit.label,
                value: unit.value,
                min: (unit.calculatorRange || {}).min,
                max: (unit.calculatorRange || {}).max,
            }));

            const unitField: FormlyFieldConfig = {
                key: `${field.id}Unit`,
                type: 'unit-select',
                className: field.units ? 'col-sm-12 inline-right unit-selector' : '',
                props: {
                    options: units,
                    disabled: field.units.length === 1 || !!field.unitSelector,
                },
                defaultValue: field.units[0].value,
            };
            inputFieldGroup.push(unitField);

            if (field.unitSelector) {
                unitField.expressions = {
                    [`model.${field.id}Unit`]: `formState.formModel.${field.category}${field.unitSelector}.${field.unitSelector}Group?.${field.unitSelector}Field.${field.unitSelector}`,
                };
            }

            inputField.expressions['props.placeholder'] = createPlaceholderFunction(`${field.id}Unit`, units);

            // only when min/max are defined, should they be added for min/max validation
            if (units[0].min !== undefined) {
                inputField.expressions['props.min'] = createTresholdFunction(field.id, 'min', units);
            }
            if (units[0].max !== undefined) {
                inputField.expressions['props.max'] = createTresholdFunction(field.id, 'max', units);
            }
        }

        imputationAndInputFieldGroup.push({
            key: `${field.id}Field`,
            wrappers: ['field-wrapper'],
            fieldGroup: inputFieldGroup,
        });

        const fieldGroupWrapper = wrapperMapping[field.type] || 'group-wrapper';
        const fieldGroup: FormlyFieldConfig = {
            key: `${field.id}Group`,
            wrappers: [fieldGroupWrapper],
            props: {
                label: field.label,
                placeholder: field.description,
                descriptionLink: field.descriptionLink,
                type: field.timestamp,
                sharedUnitSelector: field.unitSelector
            },
            fieldGroup: imputationAndInputFieldGroup,
        };

        if (field.messages) {
            fieldGroup.props.messages = field.messages;
        } 

        if (field.type === 'hidden') {
            fieldGroup.hide = true;
        }

        Object.keys(field.expressions || {}).forEach((property) => {
            if (property === 'hide') {
                fieldGroup.expressions = { hide: getExpressionProperty(field.expressions.hide, idMapping) };
            }
            
            if (['disabled', 'min', 'max', 'required'].indexOf(property) > -1) {
                if (!inputField.expressions) {
                    inputField.expressions = {};
                }

                inputField.expressions[`props.${property}`] = getExpressionProperty(getExpression(field.expressions, property), idMapping);
            }

            if (property === 'options') {
                const arrayRegex = /\[[\w|\{|\}|:|\s|\d'|,]+\]/g;
                if (!inputField.expressions) {
                    inputField.expressions = {};
                }

                inputField.expressions[`props.options`] = getExpressionProperty(field.expressions.options, idMapping)
                    .replace(arrayRegex, arrayString => {
                        let replacement = '';
                        try {
                            const array = JSON.parse(arrayString);
                            replacement = JSON.stringify(
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                array.map((entry: any) => {
                                    const option = field.options[entry];
                                    if (isNaN(entry) || !field) {
                                        return entry;
                                    }

                                    return { ...option, id: option.value };
                                })
                            );
                        } catch (err) {
                            replacement = arrayString;
                        }

                        return replacement;
                    });
            }
            if (property === 'value') {
                if (!inputField.expressions) {
                    inputField.expressions = {};
                }
                inputField.expressions[`model.${field.id}`] = getExpressionProperty(field.expressions.value, idMapping);
            }
        });
        if (field.imputable && field.required) {
            if (!inputField.expressions) {
                inputField.expressions = {};
            }
            const imputedField = idMapping[`${field.id}Imputed`];
            inputField.expressions[`props.required`] = `${imputedField} != true`;
        }

        if (this.site === Site.VtePredict && field.id === 'current_anticoag') {
            inputField.validators = {
                current_anticoag: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    expression: (c: any) => c.value === true,
                    message: 'VALIDATIONS.NO_CURRENT_ANTICOAG'
                }
            };
        }

        return fieldGroup;
    }
}

const getExpressionProperty = (expression: string, idMapping: Record<string, string>): string => {
    return expression.match(ALFANUMERIC_MATCH)
        .map(part => idMapping[part] || part)
        .join('');
};

const getExpression = (expressions: Expressions, property: string): string => {
    switch (property) {
        case 'disabled':
            return expressions.disabled;
        case 'min':
            return expressions.min;
        case 'max':
            return expressions.max;
        case 'required':
            return expressions.required;
        default:
            throw new Error(`Unexpected property: ${property}`);
    }
};

const createPlaceholderFunction = (key: string, units: Array<{label: string; value: string; min: number; max: number}>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (field: FormlyFieldConfig): any => {
        const unit = units.find(u => u.value === field.model[key]);
        if (!unit) {
            return '';
        }

        const min = field.props?.min ?? unit.min;
        const max = field.props?.max ?? unit.max;
        if (min === undefined) {
            return `≤ ${max}`;
        }
        if (max === undefined) {
            return `≥ ${min}`;
        }

        return `${min} - ${max}`;
    };
};

const createTresholdFunction = (key: string, type: string, units: Array<{label: string; value: string; min: number; max: number}>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (field: FormlyFieldConfig): any => {
        const unit = units.find(u => u.value === field.model[`${key}Unit`]);

        if (!unit || isImputed(key, field.options.formState)) {
            return '';
        }

        return type === 'min' ? unit.min : unit.max;
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isImputed = (key: string, formState: any): boolean => {
    const keyGroup = Object.keys(formState.formModel)
        .map((modelKey) => formState.formModel[modelKey])
        .filter((groups) => groups[`${key}Group`] !== undefined)[0];

    if (!keyGroup) {
        return false;
    } else {
        return keyGroup[`${key}Group`][`${key}Imputed`] === true;
    }
};

function normalize(value: string, type: string): string | boolean | number {
    if (value == null) {
        return value;
    }

    switch(type) {
        case "boolean":
            if (String(value).toLowerCase() === "true") {
                return true;
            } else if (String(value).toLowerCase() === "false") {
                return false;
            } else throw "Invalid value for boolean type";
        case "real":
            return parseFloat(value);
        case "integer":
            return parseInt(value);
        default:
            return value;
    }
}

