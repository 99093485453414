<ng-container [ngSwitch]="lang">
    <div *ngSwitchCase="Lang.Dutch" class="home-banner dutch">
        <h3>Welkom bij U-Prevent</h3>
        <p>Geachte bezoeker, </p>
        <p>
            Het medische hulpmiddel U-Prevent is door ORTEC ontwikkeld en geoptimaliseerd om de zorgprofessional te ondersteunen bij het toepassen van de CVRM-richtlijnen voor gepersonaliseerd cardiovasculair risicomanagement. 
            U-Prevent is <a routerLink="/about/mdr">CE-gemarkeerd</a> volgens de MDD en de MDR-markering is in voorbereiding. De originele 
            pilot-website is gebouwd door UMC Utrecht. Om een groot aantal gebruikers te ondersteunen en te voldoen aan de regelgeving is U-Prevent door ORTEC opnieuw ontwikkeld.
            U-Prevent is bedoeld voor gebruik door zorgprofessionals in de gezondheidszorg en is, na registratie als gebruiker, vrij toegankelijk onder een ‘Fair Use Policy’. 
            Het ontwikkelen en onderhouden van een MDR-gecertificeerd hulpmiddel voor klinische beslissingsondersteuning brengt aanzienlijke kosten met zich mee.
            Om de duurzaamheid ervan te garanderen, wordt voor gebruik dat niet binnen de ‘Fair Use Policy’ valt een vergoeding in rekening gebracht.
        </p>
        <p>
            Het U-Prevent team biedt extra <a routerLink="/about/services">diensten</a> zoals
            <a href="https://www.go-fair.org/fair-principles/" target="_blank">FAIR</a> en <a href="https://eur-lex.europa.eu/legal-content/NL/TXT/HTML/?uri=CELEX:32016R0679&from=EN"
            target="_blank">AVG</a>-comforme gegevensuitwisseling vanuit elektronische patiëntendossiers.
            Hiermee is het mogelijk om U-Prevent geautoriseerd op te starten met data vanuit het patiënten
            informatiesysteem. Voor meer informatie, neem <a routerLink="/contact">contact</a> op.
        </p>
    
        <p><a routerLink="/about/u-prevent">Het U-Prevent team.</a></p>
    </div>

    <div *ngSwitchDefault class="home-banner english">
        <h3>Welcome to U-Prevent</h3>
        <p>Dear visitor, </p>
        <p>
            The U-Prevent medical device has been recreated and optimized by ORTEC supporting the healthcare professional in applying the CVRM guidelines for personalized cardiovascular risk management for personalized cardiovascular risk management. 
            U-Prevent is <a routerLink="/about/mdr">CE marked</a> according to the MDD and marking for MDR compliancy is in progress. 
            The original pilot website was built by UMC Utrecht. To support large numbers of users and to comply with regulations U-Prevent was rebuild by ORTEC. U-Prevent is intended to be used by healthcare professionals and is freely accessible, under a fair use policy for incidental use, after registering as a user.
            Development and maintenance of an MDR-certified clinical decision support solution entails significant costs.
            To ensure its sustainability, a fee will be charged for use that does not fall within the fair use policy.
        </p>
        <p>
            The U-Prevent team develops extra <a routerLink="/about/services">services</a> like <a href="https://www.go-fair.org/fair-principles/" target="_blank">FAIR</a> and
            <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&from=EN"
                target="_blank">GDPR</a>-compliant data exchange from electronic health records. This will allow to initiate
            U-Prevent in an authorized way with data automatically uploaded into U-Prevent from a patient information system. Please <a routerLink="/contact">contact
                us</a> for more information.
        </p>
    
        <p><a routerLink="/about/u-prevent">The U-Prevent team.</a></p>
    </div>
</ng-container>