<cvrm-page-wave-background color="blue">
    <div class="calculator-overview-page">
        <h1 class="title" translate>CALCULATOR_OVERVIEW.SELECT_YOUR_CALCULATOR</h1>
        <h2 class="subtitle" translate>CALCULATOR_OVERVIEW.ASSIST_SUBTITLE</h2>

        <div *ngIf="(site$ | async)">
            <div class="selector-helper">
                <a routerLink="assist">
                    <div class="selector-helper-text" translate>CALCULATOR_OVERVIEW.ASSIST_CHOICE</div>
                </a>
            </div>
            
            <div class="calculator-matrix__header">
                <div class="calculator-matrix__header-cell-label calculcator-matrix__quick-selection"
                    translate="CALCULATOR_OVERVIEW.QUICK_SELECTION">
                </div>
                <div class="calculator-matrix__header-cell calculcator-matrix__5-10-risk"
                    translate="CALCULATOR_OVERVIEW.CALCULATE_5_10_YEAR_RISK">
                </div>
                <div class="calculator-matrix__header-cell calculcator-matrix__lifetime"
                    translate="CALCULATOR_OVERVIEW.CALCULATE_LIFETIME_TREATMENT">
                </div>
            </div>

            <div class="calculator-matrix__row">
                <div class="calculator-matrix__cell-label">
                    <cvrm-label-tile color="purple" label="CALCULATOR_OVERVIEW.PREVIOUS_CARDIO_DISEASE"
                        moreInfo="DESCRIPTIONS.ASSIST_CHOICE_CORONARY_DISEASE">
                    </cvrm-label-tile>
                </div>
                <div class="calculator-matrix__cell-calculator">
                    <a routerLink="smart2Score">
                        <cvrm-selection-tile color="purple" data-cy="smart_2_risk_score" label="CALCULATOR_OVERVIEW.SMART_2_RISK_SCORE">
                        </cvrm-selection-tile>
                    </a>
                </div>
                <div class="calculator-matrix__cell-calculator">
                    <a routerLink="smartReach">
                        <cvrm-selection-tile color="purple" data-cy="smart_reach_model" label="CALCULATOR_OVERVIEW.SMART_REACH_MODEL">
                        </cvrm-selection-tile>
                    </a>
                </div>
            </div>
            <div class="calculator-matrix__row">
                <div class="calculator-matrix__cell-label">
                    <cvrm-label-tile color="darkblue" label="CALCULATOR_OVERVIEW.TYPE_2_DIABETES"
                        moreInfo="DESCRIPTIONS.ASSIST_CHOICE_HAS_DIABETES"></cvrm-label-tile>
                </div>
                <div class="calculator-matrix__cell-calculator">
                    <a routerLink="advanceScore">
                        <cvrm-selection-tile color="darkblue" data-cy="advance_risk_score" label="CALCULATOR_OVERVIEW.ADVANCE_RISK_SCORE">
                        </cvrm-selection-tile>
                    </a>
                </div>
                <div class="calculator-matrix__cell-calculator">
                    <a routerLink="dialModel">
                        <cvrm-selection-tile color="darkblue" data-cy="dial_model" label="CALCULATOR_OVERVIEW.DIAL_MODEL"></cvrm-selection-tile>
                    </a>
                </div>
            </div>

            <br>

            <div class="calculator-matrix__header">
                <div class="calculator-matrix__header-cell-label calculcator-matrix__quick-selection">
                </div>
                <div class="calculator-matrix__header-cell calculcator-matrix__5-10-risk">
                    <div class="split">
                        <div translate="CALCULATOR_OVERVIEW.HEALTHY_70_MINUS"></div>
                        <div translate="CALCULATOR_OVERVIEW.HEALTHY_70_PLUS"></div>
                    </div>
                </div>
                <div class="calculator-matrix__header-cell calculcator-matrix__lifetime">
                </div>
            </div>

            <div class="calculator-matrix__row">
                <div class="calculator-matrix__cell-label">
                    <cvrm-label-tile color="teal" label="CALCULATOR_OVERVIEW.HEALTHY"
                        sublabel="CALCULATOR_OVERVIEW.HEALTHY_SUBLABEL">
                    </cvrm-label-tile>
                </div>
                <div class="calculator-matrix__cell-calculator">
                    <div class="split">
                        <a routerLink="score2" class="cards">
                            <cvrm-selection-tile color="teal" data-cy="score_2" label="CALCULATOR_OVERVIEW.SCORE_2">
                            </cvrm-selection-tile>
                        </a>
                        <a routerLink="score2OP" class="cards">
                            <cvrm-selection-tile color="teal" data-cy="score_2_op" label="CALCULATOR_OVERVIEW.SCORE_2_OP">
                            </cvrm-selection-tile>
                        </a>
                    </div>
                </div>
                <div class="calculator-matrix__cell-calculator">
                    <a routerLink="lifeCvd">
                        <cvrm-selection-tile color="teal" data-cy="life_cvd_model" label="CALCULATOR_OVERVIEW.LIFE_CVD_MODEL"></cvrm-selection-tile>
                    </a>
                </div>
            </div>
        </div>
    </div>
</cvrm-page-wave-background>
<cvrm-footer-wave-background ceMark="WhiteCEMark" color="blue"></cvrm-footer-wave-background>