

export enum UserType {
    Visitor = 'Visitor',
    Login = 'Login',
    Connect = 'Connect'
}

export enum SupportedLanguage {
    English = 'en',
    Dutch = 'nl',
}

// These countries have more requirements in their legislation to allow us to give them access to the result treatment form
export enum UnsupportedCountries {
    Denmark = 'Denmark',
    Latvia = 'Latvia',
    Slovenia = 'Slovenia',
    Liechtenstein = 'Liechtenstein'
}

export enum Lang {
    Bulgarian = 'bg',
    Czech = 'cs',
    Danish = 'da',
    German = 'de',
    Greek = 'el',
    English = 'en',
    Estonian = 'et',
    Finnish = 'fi',
    French = 'fr',
    Irish = 'ga',
    Croatian = 'hr',
    Hungarian = 'hu',
    Icelandic = 'is',
    Italian = 'it',
    Luxembourgish = 'lb',
    Lithuanian = 'lt',
    Latvian = 'lv',
    Maltese = 'mt',
    Dutch = 'nl',
    Norwegian = 'no',
    Polish = 'pl',
    Portuguese = 'pt',
    Romanian = 'ro',
    Slovak = 'sk',
    Slovene = 'sl',
    Spanish = 'es',
    Swedish = 'sv',
    Turkish = 'tr'
}

export enum Country {
    RestOfTheWorld = 'Rest Of The World',
    Austria = 'Austria',
    Belgium = 'Belgium',
    Bulgaria = 'Bulgaria',
    Croatia = 'Croatia',
    Cyprus = 'Cyprus',
    CzechRepublic = 'Czech Republic',
    Denmark = 'Denmark',
    Estonia = 'Estonia',
    Finland = 'Finland',
    France = 'France',
    Germany = 'Germany',
    Greece = 'Greece',
    Hungary = 'Hungary',
    Ireland = 'Ireland',
    Italy = 'Italy',
    Latvia = 'Latvia',
    Lithuania = 'Lithuania',
    Luxembourg = 'Luxembourg',
    Malta = 'Malta',
    Netherlands = 'The Netherlands',
    Poland = 'Poland',
    Portugal = 'Portugal',
    Romania = 'Romania',
    Slovakia = 'Slovakia',
    Slovenia = 'Slovenia',
    Spain = 'Spain',
    Sweden = 'Sweden',
    Iceland = 'Iceland',
    Liechtenstein = 'Liechtenstein',
    Norway = 'Norway',
    Turkey = 'Turkey'
}

export interface Consent {
    given: boolean;
    timestamp: Date;
}

export interface BaseUser {
    type?: UserType;
    agreeTermsConditions: boolean;
    muteReadUpdateNotification: boolean;
    preferredLanguage: Lang;
    cookieConsent?: boolean;
    lastUpdateRead?: string;
}

export interface VisitorUser extends BaseUser {
    type: UserType.Visitor;
    agreedToLifeHfTerms?: boolean;
}

export interface ConnectUser extends BaseUser {
    type: UserType.Connect;
    organizationName: string;
    parentUrl: string;
}

export interface LoginUser extends BaseUser {
    type: UserType.Login;
    id: string;
    email: string;
    country: Country;
    registrationNumber?: string;
    professionalFullName: string;
    emailNotificationConsent: Consent;
}

// Api model
export interface User extends Omit<LoginUser, 'type'| 'cookieConsent'> {}

export type UserState = VisitorUser | LoginUser | ConnectUser;

export interface UpdateUserModel {
    registrationNumber?: string;
    professionalFullName?: string;
    preferredLanguage?: Lang;
    country?: Country;
    agreeTermsConditions?: boolean;
    lastUpdateRead?: string;
    emailNotificationConsentGiven?: boolean;
}

export const isVisitorUser = (user: UserState): user is VisitorUser => user.type === UserType.Visitor;
export const isConnectUser = (user: UserState): user is ConnectUser => user.type === UserType.Connect;
export const isLoginUser = (user: UserState): user is LoginUser => user.type === UserType.Login;

export const isAuthenticatedUser = (userType: UserType): boolean => [UserType.Login, UserType.Connect].includes(userType);

export const matchUserType = (userType: string): UserType => UserType[userType as keyof typeof UserType] ?? undefined;
export const matchLanguage = (lang: string): Lang =>
    Object.keys(Lang).includes(lang) ? Lang[lang as keyof typeof Lang] : lang as Lang
    ?? Lang.English;

export const getSupportedLanguageList = (): Array<string> => Object.values(SupportedLanguage);
export const getUnsupportedCountryList = (): Array<string> => Object.values(UnsupportedCountries);

export const userHasCompletedRegistration = (user: LoginUser | User): boolean => {
    return user.country != null && user.professionalFullName != null;
};