import { Injectable } from '@angular/core';
import { Country, Lang } from '@app-core/user-store/user.model';
import { Site, SiteConfiguration } from '@app-shared/models/site-configuration-enum';
import { Query } from '@datorama/akita';
import { environment } from 'environments/environment';
import { Observable, filter, map } from 'rxjs';
import { GeneralState, GeneralStore } from './general.store';
import { Features, MenuItem } from './submenu.model';

@Injectable({
    providedIn: 'root'
})
export class GeneralQuery extends Query<GeneralState> {
    constructor(
        protected store: GeneralStore) {
        super(store);
    }

    public getAppVersion(): string {
        return this.getValue().appVersion;
    }

    public getSiteConfiguration(): SiteConfiguration {
        return this.getValue().siteConfiguration;
    }

    public getFeatures(): Features {
        return this.getValue().features;
    }

    public getAllowedLangForCountries(): Record<Country, Array<Lang>> {
        return this.getValue().allowedLangForCountries;
    }

    public getResourceUrl(fullPath: string): string {
        const paths = fullPath.split('/');

        let menuItem: MenuItem;

        if (paths.length === 2) {
            const parent = this.getValue().menu.find(item => item.path === paths[0]);
            if (parent && parent.children) {
                menuItem = parent.children.find(child => child.path === paths[1]);
            }
        } else {
            menuItem = this.getValue().menu.find(item => item.path === paths[0]);
        }

        return (menuItem && menuItem.data) ? menuItem.data.resourceUrl : null;
    }

    public selectSite(): Observable<Site> {
        return this.selectSiteConfiguration().pipe(map(siteConfig => siteConfig.site));
    }

    public selectSiteConfiguration(): Observable<SiteConfiguration> {
        return this.select().pipe(
            map(g => g.siteConfiguration),
            filter(siteConfiguration => siteConfiguration.site !== Site.Unknown)); // Ensure site configuration is always a valid state obtained from server
    }

    public isMdrCertified(): Observable<boolean> {
        return this.select().pipe(map(config => config.siteConfiguration.mdrCertified));
    }

    public selectAllowedLangForCountries(): Observable<Record<Country, Array<Lang>>> {
        return this.select().pipe(map(g => g.allowedLangForCountries));
    }

    public selectConfigReceived(): Observable<boolean> {
        return this.select().pipe(map(g => g.siteConfiguration.site !== Site.Unknown));
    }

    public selectFeatures(): Observable<Features> {
        return this.select().pipe(map(g => g.features));
    }

    public selectAppVersion(): Observable<string> {
        return this.select().pipe(map(state => state.appVersion));
    }

    public selectMenu(): Observable<Array<MenuItem>> {
        return this.select()
            .pipe(
                map(state => state.menu),
                map(menuItems => menuItems.filter(menuItem => !menuItem.hideInNavigation))
            );
    }
}
