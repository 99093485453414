import { Injectable } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { Query } from '@datorama/akita';
import { Observable, combineLatest, filter, map, withLatestFrom } from 'rxjs';
import { Lang, LoginUser, UserState, UserType, getSupportedLanguageList, getUnsupportedCountryList, isLoginUser } from './user.model';
import { UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
    private readonly unsupportedCountries = getUnsupportedCountryList();
    private readonly supportedLanguages = getSupportedLanguageList();

    constructor(
        protected store: UserStore,
        private readonly generalQuery: GeneralQuery) {
        super(store);
    }

    public getUser(): UserState {
        return this.getValue();
    }

    public getLang(): Lang {
        return this.getValue().preferredLanguage;
    }

    public getUserType(): UserType {
        return this.getValue().type;
    }

    public getConnectUserParentUrl(): string | undefined {
        const user = this.getValue();
        if (user.type === UserType.Connect) {
            return user.parentUrl;
        }

        return undefined;
    }

    public getShowThirdPartyContent(): boolean {
        return this.getValue().cookieConsent ?? false;
    }

    public selectUserType(): Observable<UserType> {
        return this.select().pipe(
            map((user) => user.type),
            withLatestFrom(this.generalQuery.selectFeatures()),
            map(([userType, features]) => features.requiresAuthentication == null ? UserType.Login : userType));
    }

    public selectLoginUser(): Observable<LoginUser> {
        return this.select().pipe(
            filter(user => isLoginUser(user)),
            map(user => user as LoginUser));
    }

    public selectLang(): Observable<Lang> {
        return this.select()
            .pipe(map(user => user.preferredLanguage));
    }

    public hasUnsupportedRegion(): Observable<boolean> {
        return combineLatest([
            this.select().pipe(map(user => user.type === UserType.Login ? user.country : null)),
            this.generalQuery.selectAllowedLangForCountries()
        ]).pipe(
            map(([country, allowedLangForCountries]) => country != null && (this.unsupportedCountries.includes(country) || !allowedLangForCountries[country].some(lang => this.supportedLanguages.includes(lang))))
        );
    }

    public selectCookieConsent(): Observable<boolean> {
        return this.select()
            .pipe(map(user => user.cookieConsent));
    }

    public selectCookieConsentUnanswered(): Observable<boolean> {
        return this.select()
            .pipe(map(user => user.cookieConsent == null));
    }

    public selectTermsConditions(): Observable<boolean> {
        return this.select()
            .pipe(map(user => user.agreeTermsConditions));
    }
}