<ng-container [ngSwitch]="lang">   
    <div *ngSwitchCase="Lang.Dutch" class="home-banner dutch">
        <h3>U-Prevent Updates</h3>
        <p>Geachte bezoeker,</p>
        <p>Met genoegen informeren wij u over de recente updates van onze calculatoren:</p>
        <ul>
            <li>SMART is geüpgraded naar <a routerLink="/calculators/smart2Score">SMART2</a>.</li>
            <li>Intensievere behandelingsopties van <a routerLink="/calculators/smartReach">SMART-REACH</a> zijn nu toegevoegd aan <a routerLink="/calculators/smart2Score">SMART2</a>.</li>
            <li>Nieuwe behandelingseffecten voor Bempedoïnezuur en Icosapent-ethyl zijn toegevoegd aan <a routerLink="/calculators/smart2Score">SMART2</a> en <a routerLink="/calculators/smartReach">SMART-REACH</a>.</li>
            <li>Het PCSK9-behandelingseffect biedt nu drie keuzes: Geen PCSK9-remmer, Alirocumab/evolocumab of Inclisiran.</li>
        </ul>
        <p>
            De Updates van U-Prevent (versie-informatie) kunt u vinden onder <a routerLink="/about/update">Update</a>.
            U wordt geadviseerd de nieuwe versie-informatie door te nemen voor het gebruik van U-Prevent.
        </p>
        <p><a routerLink="/about/u-prevent">Het U-Prevent team</a></p>
    </div>

    <div *ngSwitchDefault class="home-banner english">
        <h3>U-Prevent Updates</h3>
        <p>Dear visitor,</p>
        <p>We are pleased to announce that the following calculator updates have been released:</p>
        <ul>
            <li>SMART has been upgraded to <a routerLink="/calculators/smart2Score">SMART2</a>.</li>
            <li>Intensive treatment options from <a routerLink="/calculators/smartReach">SMART-REACH</a> have now been added to <a routerLink="/calculators/smart2Score">SMART2</a>.</li>
            <li>New treatment effects for Bempedoic acid and Icosapent-ethyl have been added to <a routerLink="/calculators/smart2Score">SMART2</a> and <a routerLink="/calculators/smartReach">SMART-REACH</a>.</li>
            <li>The PCSK9 treatment effect now offers three choices: No PCSK9 inhibitor, Alirocumab/evolocumab, or Inclisiran.</li>
        </ul>
        <p>
            The U-Prevent updates (release notes) can be found on the website under <a routerLink="/about/update">Update</a>.
            Please refer to the new release notes before using U-Prevent.  
        </p>
        <p><a routerLink="/about/u-prevent">The U-Prevent team</a> </p>
    </div>
</ng-container>