import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { isAuthenticatedUser } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { getUpdatedModel, patchHidableFields } from '@app-features/calculators/calculator-form/calculator-form.component';
import { HidableField, HttpError } from '@app-features/calculators/calculator.model';
import { CalculatorService } from '@app-features/calculators/calculator.service';
import { PatientFields, PatientModel, Value } from '@app-features/calculators/patient-data/patient-data.model';
import { PatientDataQuery } from '@app-features/calculators/patient-data/patient-data.query';
import { PatientDataService } from '@app-features/calculators/patient-data/patient-data.service';
import { NotificationComponent } from '@app-shared/components/error-notification/error-notification.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BolNotificationService } from '@ortec/bolster/notification';
import { debounceTime, delay, filter, finalize, first, map, switchMap, tap } from 'rxjs';

interface RecommendedCalculator {
    routerLink: string;
    color: string;
    label: string;
}

const SMART_2_SCORE_CALCULATOR = {
    routerLink: '/calculators/smart2Score',
    color: 'purple',
    label: 'CALCULATOR_OVERVIEW.SMART_2_RISK_SCORE'
};

const SMART_MODEL_CALCULATOR = {
    routerLink: '/calculators/smartReach',
    color: 'purple',
    label: 'CALCULATOR_OVERVIEW.SMART_REACH_MODEL'
};

const ADVANCE_RISK_CALCULATOR = {
    routerLink: '/calculators/advanceScore',
    color: 'darkblue',
    label: 'CALCULATOR_OVERVIEW.ADVANCE_RISK_SCORE'
};

const DIAL_MODEL_CALCULATOR = {
    routerLink: '/calculators/dialModel',
    color: 'darkblue',
    label: 'CALCULATOR_OVERVIEW.DIAL_MODEL'
};

const LIFE_CVD_MODEL = {
    routerLink: '/calculators/lifeCvd',
    color: 'teal',
    label: 'CALCULATOR_OVERVIEW.LIFE_CVD_MODEL'
};

const SCORE_2 = {
    routerLink: '/calculators/score2',
    color: 'teal',
    label: 'CALCULATOR_OVERVIEW.SCORE_2'
};

const SCORE_2_OP = {
    routerLink: '/calculators/score2OP',
    color: 'teal',
    label: 'CALCULATOR_OVERVIEW.SCORE_2_OP'
};

@UntilDestroy()
@Component({
    selector: 'cvrm-calculator-assist-choice',
    templateUrl: './calculator-assist-choice.component.html',
    styleUrls: ['./calculator-assist-choice.component.scss']
})
export class CalculatorAssistChoiceComponent implements OnInit {
    private readonly calculatorName = 'assistedChoice';
    private previousHidableFields: Array<HidableField> = null;

    public form = new UntypedFormGroup({});
    public model: PatientModel = {};
    public options: FormlyFormOptions;
    public fields: Array<FormlyFieldConfig> = [];
    public calculator = {
        color: 'blue',
        title: ''
    };
    public loadingData = false;
    public recommendedCalculator: RecommendedCalculator = null;
    
    constructor(
        private readonly userQuery: UserQuery,
        private readonly translateService: TranslateService,
        private readonly bolNotificationService: BolNotificationService,
        private readonly patientDataService: PatientDataService,
        private readonly patientDataQuery: PatientDataQuery,
        private readonly calculatorService: CalculatorService,
        private readonly generalQuery: GeneralQuery
    ) { }

    public ngOnInit(): void {
        this.calculatorService.setActiveCalculator(this.calculatorName);
        this.calculatorService.toggleFormDisable(false);
        this.fields = this.createIntakeFormFields();
        this.options = {
            formState: {
                formModel: this.model
            }
        };

        if (this.generalQuery.getFeatures().prefillPatientData && 
            this.patientDataQuery.hasPatientDataRequest() &&
            this.patientDataQuery.isPreloadFeatureOn()) {

            this.loading(true);

            this.userQuery.selectUserType().pipe(
                filter(userType => isAuthenticatedUser(userType)),
                first(),
                switchMap(() => this.patientDataService.getPatientData(this.calculatorName, this.fields).pipe(
                    finalize(() => this.loading(false))
                )),
                untilDestroyed(this)
            ).subscribe({
                next: patientModel => setTimeout(() => this.form.patchValue(patientModel), 0),
                error: error => this.handleDataLoadError(error)
            });

            this.form.valueChanges
                .pipe(
                    delay(0),
                    debounceTime(150),
                    map(() =>  this.patientDataQuery.getDataForCalculator(this.calculatorName)),
                    tap(patientDataFields => {
                        const patchForm = (fieldsToPatch: Array<HidableField>): void => {
                            const model = getUpdatedModel(fieldsToPatch, patientDataFields, this.form.value);
                            this.form.patchValue(model);
                        };
                    
                        setTimeout(() => {
                            this.previousHidableFields = patchHidableFields(
                                patientDataFields, this.fields, this.previousHidableFields, patchForm);
                        } , 0);
                    }),
                    tap((patientDataFields) => this.patientDataService.storePatientDataOverrides(this.calculatorName, this.form, patientDataFields)),
                    untilDestroyed(this)
                ).subscribe();
        }
    }

    public updateRecommended(model: PatientModel): void {
        const flatModel = this.getFlatModel(model);
        this.recommendedCalculator = null;

        if (flatModel.main === '10') {
            if (flatModel.cardioVascularDisease === true) {
                this.recommendedCalculator = SMART_2_SCORE_CALCULATOR;
            } else if (flatModel.cardioVascularDisease === false) {
                if (flatModel.diabetes === true) {
                    this.recommendedCalculator = ADVANCE_RISK_CALCULATOR;
                } else if (flatModel.diabetes === false) {
                    if (flatModel.age === 'lower') {
                        this.recommendedCalculator = SCORE_2;
                    } else if (flatModel.age === 'higher') {
                        this.recommendedCalculator = SCORE_2_OP;
                    }
                }
            }
        } else if (flatModel.main === 'lifetime') {
            if (flatModel.cardioVascularDisease === true) {
                if (flatModel.diabetes === true) {
                    this.recommendedCalculator = DIAL_MODEL_CALCULATOR;
                } else if (flatModel.diabetes === false) {
                    this.recommendedCalculator = SMART_MODEL_CALCULATOR;
                }
            } else if (flatModel.cardioVascularDisease === false) {
                if (flatModel.diabetes === true) {
                    this.recommendedCalculator = DIAL_MODEL_CALCULATOR;
                } else if (flatModel.diabetes === false) {
                    this.recommendedCalculator = LIFE_CVD_MODEL;
                }
            }
        }
    }

    public getFlatModel(model: PatientModel): Record<string, Value> {
        return Object.keys(model.choice)
            .map(groupId => model.choice[groupId])
            .map(group => group[Object.keys(group)[0]])
            .map(input => {
                const key = Object.keys(input)[0];

                return key ? { [key]: (input as PatientFields)[key] } : null;
            })
            .filter(x => x)
            .reduce((obj, prop) => ({ ...obj, ...prop }));
    }

    private loading(toggle: boolean): void {
        this.calculatorService.toggleFormDisable(toggle);
        this.loadingData = toggle;
    }

    private handleDataLoadError(httpErrorResponse: HttpErrorResponse): void {
        const error = httpErrorResponse.error as HttpError;
        const errorMessage = this.translateService.instant(
            'CALCULATORS.DATA_LOAD_ERROR'
        );
    
        const input = {
            component: NotificationComponent,
            data: { 
                PrimaryErrorMessage: errorMessage, 
                SecondaryErrorMessage: error?.innerErrorMessage,
                StatusCode: error?.status
            }
        };

        this.bolNotificationService.negative(input, 'error', '', { duration: 5000 });
        this.patientDataService.clearStore();
    }

    private createIntakeFormFields(): Array<FormlyFieldConfig> {
        return this.calculatorService.createIntakeForm([
            {
                id: 'main',
                type: 'button-select',
                label: 'FIELDS.MAIN_CHOICE',
                category: 'choice',
                timestamp: 'patient',
                options: [
                    { label: 'FIELDS.CALCULATE_10_YEAR', value: '10' },
                    { label: 'FIELDS.CALCULATE_LIFETIME', value: 'lifetime' },
                ],
            },
            {
                id: 'cardioVascularDisease',
                type: 'select',
                label: 'FIELDS.HISTORY_CARDIOVASCULAR_DISEASE',
                description: 'DESCRIPTIONS.ASSIST_CHOICE_CORONARY_DISEASE',
                timestamp: 'condition',
                category: 'choice',
                options: [
                    { label: 'FIELDS.YES', value: true },
                    { label: 'FIELDS.NO', value: false }
                ]
            },
            {
                id: 'diabetes',
                type: 'select',
                label: 'FIELDS.HAS_DIABETES',
                description: 'DESCRIPTIONS.ASSIST_CHOICE_HAS_DIABETES',
                category: 'choice',
                timestamp: 'condition',
                options: [
                    { label: 'FIELDS.YES', value: true },
                    { label: 'FIELDS.NO', value: false }
                ],
                expressions: {
                    hide: `!((main === '10' && cardioVascularDisease === false) || (main === 'lifetime' && cardioVascularDisease != null))`,
                }
            },
            {
                id: 'age',
                type: 'select',
                label: 'FIELDS.AGE',
                category: 'choice',
                options: [
                    { label: 'FIELDS.LOWER_SEVENTY', value: 'lower' },
                    { label: 'FIELDS.HIGHER_SEVENTY', value: 'higher' }
                ],
                expressions: {
                    hide: `!(main === '10' && cardioVascularDisease === false && diabetes === false)`,
                }
            }
        ]);
    }
}
