import { Observable, tap } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Calculator } from '@app-features/calculators/calculator.model';
import { CalculatorQuery } from '@app-features/calculators/calculator.query';
import { CalculatorService } from '@app-features/calculators/calculator.service';

@Component({
    selector: 'cvrm-calculator-description',
    templateUrl: './calculator-description.component.html',
    styleUrls: ['./calculator-description.component.scss'],
})
export class CalculatorDescriptionComponent implements OnInit {
    private calculatorName: string;
    public calculator$: Observable<Calculator>;
    public fullPath: string;
    
    constructor(
        private readonly calculatorService: CalculatorService,
        private readonly calculatorQuery: CalculatorQuery,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {}

    public ngOnInit(): void {
        this.calculatorName = this.route.snapshot.params.calculator;
        this.calculatorService.setActiveCalculator(this.calculatorName);
        this.calculator$ = this.calculatorQuery.selectActive().pipe(
            tap((calculator) => {
                if(!calculator) {
                    this.router.navigateByUrl('/page-not-found');
                    
                    return;
                }
            })
        );
        this.fullPath = `calculators/description/${this.calculatorName}`;
    }

    public toForm(): void {
        this.router.navigate(['calculators', this.calculatorName]);
    }
}
