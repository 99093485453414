export interface Environment {
    name: EnvironmentName;
    configuration: Configuration;
    requiresAuthenticationConfig: RequiresAuthenticationConfig;
    timeStamp: string;
}

export enum EnvironmentName {
    Test,
    Pilot,
    Production
}

interface Configuration {
    apiUrl: string;
    messagingParentUrls?: Array<string>;
    
    useAppInsights: boolean;
}

interface RequiresAuthenticationConfig {
    urls: Array<string>;
    b2cAuthorizeUrl: string; // The B2C endpoint for the authorize request
    b2cLogoutUrl: string; // The B2C endpoint for the logout request
    b2cConnectionName: string; // Name of the B2C Connection in Auth0, to skip the Auth0 dialog
    b2cClientId: string; // The clientId of the U-Prevent application registration in Azure AD B2C, must have permissions openid and offline_access
    authConfig: AuthConfiguration;
}

interface AuthConfiguration {
    clientID: string;
    domain: string;
    responseType: string;
    scope: string;
}