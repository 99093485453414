import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { GeneralService } from '@app-core/general-store/general.service';
import { getSupportedLanguageList, getUnsupportedCountryList, Lang, UserState, UserType } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { UserService } from '@app-core/user-store/user.service';
import { HttpError } from '@app-features/calculators/calculator.model';
import { ButtonType, DialogConfig, ResponseType } from '@app-shared/components/dialog-component/dialog.component';
import { DialogService } from '@app-shared/components/dialog-component/dialog.service';
import { NotificationComponent } from '@app-shared/components/error-notification/error-notification.component';
import { CookieGroup } from '@app-shared/models/one-trust.model';
import { Site } from '@app-shared/models/site-configuration-enum';
import { AuthorizationService } from '@app-shared/services/authorization.service';
import { LocalStorageKey, LocalStorageService } from '@app-shared/services/local-storage.service';
import { LoggingService } from '@app-shared/services/logging.service';
import { OneTrustService } from '@app-shared/services/onetrust.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BolNotificationService } from '@ortec/bolster/notification';
import { cache } from '@ortec/soca-web-ui';
import { environment } from 'environments/environment';
import { EnvironmentName } from 'environments/environment.config';
import { isEqual } from 'lodash';
import { combineLatest, delay, distinctUntilChanged, filter, map, Observable, switchMap, tap, timer } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private readonly parentUrl = environment.configuration.messagingParentUrls.find(x => (parent !== window ? document.referrer : document.location.origin).startsWith(x));
    private readonly supportedLanguages = getSupportedLanguageList();
    private readonly unsupportedCountries = getUnsupportedCountryList();
    private readonly allowedLangForCountries$ = this.generalQuery.selectAllowedLangForCountries().pipe(
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        cache()
    );
    private readonly features$ = this.generalQuery.selectFeatures();
    
    public readonly site$: Observable<Site> = this.generalQuery.selectSite().pipe(
        tap(site => this.setTabName(site)),
        cache()
    );
    
    public appVersion$ = this.generalService.getBuildNumber().pipe(cache());

    public unansweredCookieConsent$ = combineLatest([
        this.userQuery.selectCookieConsentUnanswered(),
        this.features$
    ]).pipe(
        // this is introduced to subdue the old cookie consent question when onetrust is enabled
        map(([consent, features]) => features.requiresAuthentication == null && consent)
    );
    public user$ = this.userQuery.select().pipe(cache());

    public shouldReadUpdate$ = combineLatest([
        this.user$,
        this.features$,
        this.appVersion$
    ]).pipe(
        filter(([user, features, ]) => features.requiresAuthentication != null || user.cookieConsent != null),
        map(([user, , appVersion]) => this.shouldReadUpdate(user, appVersion))
    );

    public translationLoaded$ = this.user$.pipe(
        map(user => user.preferredLanguage),
        tap(lang => this.supportedLanguages.includes(lang) 
            ? this.translate.use(lang)
            : this.translate.use(Lang.English)
        ), 
        map(l => l != null)
    );

    public availableLanguages$ = combineLatest([
        this.user$,
        this.allowedLangForCountries$,
    ]).pipe(
        map(([user, allowedLangForCountries]) => user.type === UserType.Login
            ? user.country != null 
                ? allowedLangForCountries[user.country].filter(lang => lang === user.preferredLanguage || this.supportedLanguages.includes(lang))
                : [Lang.English]
            : Object.values(Lang).filter(lang => this.supportedLanguages.includes(lang)))
    );

    public noAvailableLanguages$ = this.userQuery.hasUnsupportedRegion();

    public currentLanguageNotSupported$ = this.user$.pipe(
        map(user => user.preferredLanguage),
        map(lang => !this.supportedLanguages.includes(lang))
    );

    public showLifeHfStartPage$ = combineLatest([
        this.generalQuery.selectSite(),
        this.user$
    ]).pipe(
        map(([site, user]) => site === Site.LifeHf && 
            user.type === UserType.Visitor &&
            user.agreedToLifeHfTerms === false)
    );

    public isProd = environment.name === EnvironmentName.Production;
    public isMdrCertified$ = this.generalQuery.isMdrCertified();

    constructor(
        public translate: TranslateService,
        private readonly authorizationService: AuthorizationService,
        private readonly userQuery: UserQuery,
        private readonly userService: UserService,
        private readonly generalService: GeneralService,
        private readonly generalQuery: GeneralQuery,
        private readonly dialogService: DialogService,
        private readonly bolNotificationService: BolNotificationService,
        private readonly router: Router,
        private readonly loggingService: LoggingService,
        private readonly otService: OneTrustService,
        private titleService: Title
    ) { }

    public ngOnInit(): void {
        this.startGracePeriodAnnouncement();

        if (this.generalQuery.getFeatures().requiresAuthentication != null) {
            this.handleUserAuthentication();

            this.setupOneTrust();

        } else if (this.parentUrl != null) {
            this.authorizationService.clearUserSession();
            this.userService.updateUserToConnect(this.parentUrl, "Connect User");
        } else {
            this.userService.updateUserToVisitor();
        }

        if (environment.configuration.useAppInsights) {
            this.setupLoggingToAI();
        }

        const defaultLang = location.host.endsWith('.com') ? Lang.English : Lang.Dutch;
        this.translate.addLangs(Object.values(Lang));
        this.translate.setDefaultLang(defaultLang);    
    }

    public goToAuthentication(): void {
        this.router.navigate(['/authenticate']);
    }
    
    public agreeCookie(): void {
        this.userService.updateCookieConsent(true);
    }

    public declineCookie(): void {
        this.userService.updateCookieConsent(false);
    }

    public agreeReadUpdate(): void {
        this.router.navigateByUrl('/about/update');
    }

    public declineReadUpdate(): void {
        this.userService.declinedToReadUpdate();
    }

    private setupOneTrust(): void {
        this.otService.initialize(this.isProd);

        combineLatest([
            this.otService.selectOneTrust(),
            this.user$.pipe(map(user => user.preferredLanguage))    
        ]).pipe(
            delay(20), // this is needed to ensure that scripts have been loaded
            untilDestroyed(this)
        ).subscribe({
            next: ([ot, lang]) => this.supportedLanguages.includes(lang) 
                ? ot?.changeLanguage(lang)
                : ot?.changeLanguage(Lang.English)
        });
    }

    private shouldReadUpdate(user: UserState, appVersion: string): boolean {
        if (user.muteReadUpdateNotification) {
            return false;
        }

        switch (user.type) {
            case UserType.Visitor:
                return false;
            case UserType.Login:
            case UserType.Connect:
                return user.lastUpdateRead !== appVersion;            
        }
    }

    private handleUserAuthentication(): void {
        let token = LocalStorageService.getCurrentToken();

        // clear previous session if it was of type Connect
        if (token != null && token.userType === UserType.Connect) {
            this.authorizationService.clearUserSession(); 
            token = null;
        }

        const urlParams = new URLSearchParams(window.location.search);
        // if there is a origin Id try to update user to Connect
        const originId = urlParams.get('originId');
        if (originId != null) {
            this.getConnectSession(originId);
        } else if (token != null) {
            this.authorizationService.rescheduleRefreshToken();
        
            if (token.userType === UserType.Login) {
                this.continueLoginSession();
            }
        } else  {
            this.userService.updateUserToVisitor();
        }
        
    }

    private continueLoginSession(): void {
        this.userService.getLoginUser().subscribe({
            next: user => this.userService.updateUserToLogin(user),
            error: (error: HttpErrorResponse) => {
                this.handleError(error);
                this.authorizationService.logout();
            }
        });
    }

    private getConnectSession(originId: string): void {
        this.authorizationService.authenticateAsGuest(originId).subscribe({
            next: token =>  this.userService.updateUserToConnect(this.parentUrl, token.organization),
            error: error => {
                this.handleError(error);
                this.authorizationService.clearUserSession();
                this.userService.updateUserToVisitor();
            }
        });
    }

    private setupLoggingToAI(): void {
        const cookieConsent$ = this.generalQuery.selectFeatures().pipe(
            switchMap(features => features.requiresAuthentication != null 
                ? this.otService.selectActiveCookieGroups().pipe(map(cookieGroups => cookieGroups.includes(CookieGroup.PerformanceCookies)))
                : this.userQuery.selectCookieConsent().pipe(filter(consent => consent != null))));

        this.loggingService.setInstrumentationKey().pipe(
            switchMap(() => cookieConsent$),       
            untilDestroyed(this)
        ).subscribe(cookieConsent =>
            this.loggingService.configureAppInsights(cookieConsent)
        );

        combineLatest([
            this.authorizationService.isAuthenticated$(),
            this.loggingService.isApplicationInsightsLoaded
        ]).pipe(
            filter(([, isAppInsightsLoaded]) => isAppInsightsLoaded),
            distinctUntilChanged((previous, current) => isEqual(previous, current)),
            untilDestroyed(this)
        ).subscribe(() => {
            this.loggingService.addCustomMetrics(LocalStorageService.getCurrentToken());
        });
        
    }

    private handleError(httpErrorResponse: HttpErrorResponse): void {
        const error = httpErrorResponse.error as HttpError;

        const input = {
            component: NotificationComponent,
            data: {
                PrimaryErrorMessage: error.errorMessage, 
                SecondaryErrorMessage: error?.innerErrorMessage,
                StatusCode: error?.status
            }
        };

        this.bolNotificationService.negative(input, 'error', '', { duration: 5000 });
    }

    private setTabName(site: Site): void {
        switch (site) {
            case Site.Default:
                this.titleService.setTitle('U-Prevent');
                break;
            case Site.VtePredict:
                this.titleService.setTitle('Vte-Predict');
                break;
            case Site.LifeHf:
                this.titleService.setTitle('Life-Hf');
                break;
            default:
                this.titleService.setTitle('Error');
                break;
        }
    }

    private startGracePeriodAnnouncement(): void {
        // the announcement will appear after some delay, in order not to disturb the user immediately
        timer(30000, 1000 * 60 * 60 * 24).pipe(
            switchMap(() => combineLatest([
                this.generalQuery.selectFeatures(),
                this.site$,
                this.userQuery.selectUserType()
            ])),
            filter(([features, , userType]) => features.requiresAuthentication?.gracePeriod === true && userType === UserType.Visitor),
            untilDestroyed(this)
        ).subscribe({
            next: ([, site,]) => {
                const announcement = LocalStorageService.getItem(LocalStorageKey.gracePeriodAnnouncement);                        
                if (announcement == null || parseInt(announcement) < new Date().setHours(0, 0, 0, 0)) {
                    this.showAnnouncement(site);
                }    
            }
        });
    }

    private showAnnouncement(site: Site): void {
        const setAnnouncementAcknowledged = (): void => {
            const date = new Date().setHours(0, 0, 0, 0).toString();
            LocalStorageService.setItem(LocalStorageKey.gracePeriodAnnouncement, date);
        };

        const input: DialogConfig = {
            announcementText: 'GRACE_PERIOD_ANNOUNCEMENT.ANNOUNCEMENT',
            bodyText: 'GRACE_PERIOD_ANNOUNCEMENT.BODY',
            agreeBtn: {
                text: 'GRACE_PERIOD_ANNOUNCEMENT.AGREE',
                type: ButtonType.Positive
            },
            declineBtn: {
                text: 'GRACE_PERIOD_ANNOUNCEMENT.DECLINE',
                type: ButtonType.Regular
            },
            site: site
        };

        const modalRef = this.dialogService.openAnnouncementModal(input);

        this.dialogService.awaitResponse(modalRef).subscribe(response => {
            switch (response) {
                case ResponseType.Positive:
                    setAnnouncementAcknowledged();
                    this.router.navigate(['/authenticate']);
                    modalRef.close();
                    break;
                case ResponseType.Cancel:
                case ResponseType.Negative:
                    setAnnouncementAcknowledged();
                    modalRef.close();
                    break;
            }
        });
    }
}
