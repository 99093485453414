import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Message } from '@app-features/calculators/calculator.model';
import { FieldWrapper } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { delay, EMPTY, map, Observable } from 'rxjs';

@Component({
    selector: 'app-result-row-wrapper',
    templateUrl: './result-row-wrapper.component.html',
    styleUrls: ['./result-row-wrapper.component.scss']
})
export class ResultRowWrapperComponent extends FieldWrapper implements OnInit {
    @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) public fieldComponent: ViewContainerRef;

    public wrapperLabel = '';
    public description = '';
    public isShowDescription = false;
    public message$: Observable<Message>;
    
    constructor(
        private readonly translateService: TranslateService,
        private readonly cdRef: ChangeDetectorRef) {
        super();
    }

    @HostListener('window:click', ['$event']) public clickOutsideListener(): void {
        this.hideDescription();
    }

    public ngOnInit(): void {
        this.wrapperLabel = this.props.label;
        this.field.props.label = '';
        this.description = this.props.placeholder;

        this.message$ = this.field.props.messages == null
            ? EMPTY
            : this.formControl.valueChanges.pipe(
                delay(0),
                map(field => {
                    const value = field[this.fieldKey + 'Field'][this.fieldKey];
                    const index = this.field.props.messages.findIndex((m: Message) => m.value === value.toString());
                    return this.props.messages[index];
                }));
    }

    public toggleDescription(): void {
        const isShown = this.isShowDescription;

        setTimeout(() => {
            if (isShown) {
                this.hideDescription();
            } else {
                this.showDescription();
            }

            this.cdRef.detectChanges();
        });   
    }

    public showDescription(): void {
        this.description = this.translateService.instant(this.props.placeholder);
        this.isShowDescription = true;
    }

    public hideDescription(): void {
        this.isShowDescription = false;
    }

    public get fieldKey(): string {
        return (this.key as string).split('Group')[0];
    }
}