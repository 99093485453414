import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot } from '@angular/router';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { UserType } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { combineLatest, map } from 'rxjs';

export const visitorGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const user$ = inject(UserQuery).select();
    const features$ = inject(GeneralQuery).selectFeatures();
    const router = inject(Router);
    const urlTree = state.url.split('?');
    return combineLatest([user$, features$]).pipe(
        map(([user, features]) =>
            features.requiresAuthentication == null || features.requiresAuthentication?.gracePeriod === true || user.type !== UserType.Visitor 
                ? true
                : router.createUrlTree(['manual', 'information'], { queryParams: { returnUrl: urlTree[0] }}))
    );
};