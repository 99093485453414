import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { UserType } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { Calculator, Session } from '@app-features/calculators/calculator.model';
import { CalculatorQuery } from '@app-features/calculators/calculator.query';
import { CalculatorService, ExportPatientData, LIFEHF_RISK_KEYS, RISK_KEYS } from '@app-features/calculators/calculator.service';
import { ChartLabels } from '@app-features/calculators/components/chart';
import { SnackbarContent } from '@app-features/calculators/components/snackbar/snackbar.model';
import { IntakeFieldConfig } from '@app-features/calculators/model/formatted-intake.model';
import { getDataForCalculator, PatientData, PatientDataField, PatientFieldCategories, PatientModel, Value } from '@app-features/calculators/patient-data/patient-data.model';
import { PatientDataQuery } from '@app-features/calculators/patient-data/patient-data.query';
import { ButtonType, DialogConfig } from '@app-shared/components/dialog-component/dialog.component';
import { DialogService } from '@app-shared/components/dialog-component/dialog.service';
import { Site } from '@app-shared/models/site-configuration-enum';
import { TabsService } from '@app-shared/services/tabs.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, debounceTime, map, Observable, of, Subject, switchMap, tap, withLatestFrom } from 'rxjs';
import { ExpectancyGraphLabels } from '../components/expectancy-graph/expectancy-graph.component';
import { ResultTenYearsStatisticsLabels } from '../components/result-ten-years-statistics/result-ten-years-statistics.component';

const ONE_YEAR_VTE_RECURRENCE_TITLE_KEY = 'CALCULATORS.ONE_YEAR_VTE_RECURRENCE_TREATMENT_TITLE';
const ONE_YEAR_BLEEDING_TITLE_KEY = 'CALCULATORS.ONE_YEAR_BLEEDING_TREATMENT_TITLE';
const FIVE_YEARS_VTE_RECURRENCE_TITLE_KEY = 'CALCULATORS.FIVE_YEAR_VTE_RECURRENCE_TREATMENT_TITLE';
const FIVE_YEARS_BLEEDING_TITLE_KEY = 'CALCULATORS.FIVE_YEAR_BLEEDING_TREATMENT_TITLE';
const TWO_YEARS_TITLE_KEY = 'CALCULATORS.TWO_YEAR_TREATMENT_TITLE';
const TWO_YEARS_TITLE_HF_KEY = 'CALCULATORS.TWO_YEAR_TREATMENT_TITLE_HF';
const FIVE_YEARS_TITLE_KEY = 'CALCULATORS.FIVE_YEAR_TREATMENT_TITLE';
const FIVE_YEARS_TITLE_HF_KEY = 'CALCULATORS.FIVE_YEAR_TREATMENT_TITLE_HF';
const TWO_YEARS_SECONDARY_TITLE_KEY = 'CALCULATORS.TWO_YEAR_SECONDARY_TREATMENT_TITLE';
const TWO_YEARS_SECONDARY_TITLE_HF_KEY = 'CALCULATORS.TWO_YEAR_SECONDARY_TREATMENT_TITLE_HF';
const FIVE_YEARS_SECONDARY_TITLE_KEY = 'CALCULATORS.FIVE_YEAR_SECONDARY_TREATMENT_TITLE';
const FIVE_YEARS_SECONDARY_TITLE_HF_KEY = 'CALCULATORS.FIVE_YEAR_SECONDARY_TREATMENT_TITLE_HF';
const TEN_YEARS_TITLE_KEY = 'CALCULATORS.TEN_YEAR_TREATMENT_TITLE';
const FIVE_YEARS_HEARTFAILURE_TITLE_KEY = 'CALCULATORS.FIVE_YEAR_HEARTFAILURE_TREATMENT_TITLE';
const TEN_YEARS_HEARTFAILURE_TITLE_KEY = 'CALCULATORS.TEN_YEAR_HEARTFAILURE_TREATMENT_TITLE';
const LIFETIME_TITLE_KEY = 'CALCULATORS.LIFETIME_TITLE';
const EXPECTANCY_TITLE_KEY = 'CALCULATORS.LIFETIME_DISEASE_FREE_CHANCE';
const EXPECTANCY_TITLE_HF_KEY = 'CALCULATORS.LIFETIME_DISEASE_FREE_CHANCE_HF';
const EXPECTANCY_SECONDARY_TITLE_KEY = 'CALCULATORS.LIFETIME_DISEASE_FREE_SECONDARY_CHANCE';
const EXPECTANCY_SECONDARY_TITLE_HF_KEY = 'CALCULATORS.LIFETIME_DISEASE_FREE_SECONDARY_CHANCE_HF';
const DISEASE_TITLE_KEY = 'CALCULATORS.DISEASE_RISK_TITLE';
const MORTALITY_TITLE_KEY = 'CALCULATORS.MORTALITY_RISK_TITLE';
const AGES_KEY = 'CALCULATORS.AGES';
const PERCENTAGE_KEY = 'CALCULATORS.PERCENTAGE';
const CURRENT_KEY = 'CALCULATORS.CURRENT';
const FUTURE_KEY = 'CALCULATORS.FUTURE';
const SUFFIX = 'Future';

@UntilDestroy()
@Component({
    selector: 'cvrm-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit, OnDestroy {
    private readonly endSessionTrigger = new Subject<boolean>();
    private readonly subjectRecalculate = new Subject<PatientModel>();
    private readonly parentUrl = this.userQuery.getConnectUserParentUrl();
    
    private prepareForPrint = false;
    private calculatorName = '';
    
    public Site = Site;
    public calculator$: Observable<Calculator>;
    public loading$ = of(true);
    public error$ = of(null);
    public intake$: Observable<Array<Array<Array<IntakeFieldConfig>>>>;
    public patientData$ = this.patientDataQuery.selectPatientData();
    public clipboardContent$: Observable<string>;
    public treatmentForm = new UntypedFormGroup({});
    public treatmentModel: PatientModel;
    public treatmentFields: Array<FormlyFieldConfig> = [];
    public graphTabs$: Observable<Array<{ key: string; label: string, selected: boolean }>>;
    public oneYearVteRecurrenceLabels: ChartLabels;
    public oneYearBleedingLabels: ChartLabels;
    public fiveYearVteRecurrenceLabels: ChartLabels;
    public fiveYearBleedingLabels: ChartLabels;
    public twoYearLabels: ChartLabels;
    public fiveYearLabels: ChartLabels;
    public twoYearSecondaryLabels: ChartLabels;
    public fiveYearSecondaryLabels: ChartLabels;
    public tenYearLabels: ChartLabels;
    public fiveYearWithHeartFailureLabels: ChartLabels;
    public tenYearWithHeartFailureLabels: ChartLabels;
    public lifetimeLabels: ChartLabels;
    public expectancyLabels: ChartLabels;
    public expectancySecondaryLabels: ChartLabels;
    public diseaseRiskLabels: ChartLabels;
    public mortalityRiskLabels: ChartLabels;
    public isDesktop = false;
    public copySuccessMessage: SnackbarContent = { text: '' };
    
    public site$ = this.generalQuery.selectSite();
    public countryHasNoSupportedLanguage$ = this.userQuery.hasUnsupportedRegion();

    public readonly hfOverallExpectancyGraphLabels: ExpectancyGraphLabels = {
        additionalYearsGainTitle: 'CALCULATORS.YEARS_GAIN_DISEASE_FREE_OVERALL_HF',
        additionalYearsLossTitle: 'CALCULATORS.YEARS_LOSS_DISEASE_FREE_OVERALL_HF',
        additionalYearsGainInfo: 'RESULTS.CVD_YEARS_GAIN_INFO_OVERALL_HF',
        additionalYearsLossInfo: 'RESULTS.CVD_YEARS_LOSS_INFO_OVERALL_HF',
        cvdFreeLifeExpectancyInfo: 'RESULTS.CVD_FREE_LIFE_EXPECTANCY_INFO_OVERALL_HF',
        ageExpectancyDiseaseFree: 'CALCULATORS.AGE_EXPECTANCY_DISEASE_FREE_OVERALL_HF',
    };

    public readonly hfExpectancyGraphLabels: ExpectancyGraphLabels = {
        additionalYearsGainTitle: 'CALCULATORS.YEARS_GAIN_DISEASE_FREE_HOSPITAL_HF',
        additionalYearsLossTitle: 'CALCULATORS.YEARS_LOSS_DISEASE_FREE_HOSPITAL_HF',
        additionalYearsGainInfo: 'RESULTS.CVD_YEARS_GAIN_INFO_HOSPITAL_HF',
        additionalYearsLossInfo: 'RESULTS.CVD_YEARS_LOSS_INFO_HOSPITAL_HF',
        cvdFreeLifeExpectancyInfo: 'RESULTS.CVD_FREE_LIFE_EXPECTANCY_INFO_HOSPITAL_HF',
        ageExpectancyDiseaseFree: 'CALCULATORS.AGE_EXPECTANCY_DISEASE_FREE_HOSPITAL_HF',
    };

    public readonly hfExpectancyLabels: ResultTenYearsStatisticsLabels = {
        currentRiskInfo: 'RESULTS.TYPE_CURRENT_RISK_INFO_HF',
        treatmentIncreaseInfo: 'RESULTS.TYPE_TREATMENT_INCREASE_INFO_HF',
        treatmentReductionInfo: 'RESULTS.TYPE_TREATMENT_REDUCTION_INFO_HF',
    };

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly calculatorQuery: CalculatorQuery,
        private readonly calculatorService: CalculatorService,
        private readonly patientDataQuery: PatientDataQuery,
        private readonly translateService: TranslateService,
        private readonly deviceDetectorService: DeviceDetectorService,
        private readonly userQuery: UserQuery,
        private readonly generalQuery: GeneralQuery,
        private readonly tabService: TabsService,
        private readonly dialogService: DialogService
    ) {}

    public ngOnInit(): void {
        this.calculatorName = this.route.snapshot.params.calculator;

        this.tabService.broadcastResultPageOpened();

        if (!this.calculatorQuery.getActiveId()) {
            this.router.navigate([this.calculatorName], {
                relativeTo: this.route.parent,
            });
            
            return;
        }

        this.calculatorService.setActiveCalculator(this.calculatorName);
        this.calculator$ = this.calculatorQuery.selectActive();
        this.loading$ = this.calculatorQuery.selectLoading();
        this.error$ = this.calculatorQuery.selectError();
                
        this.intake$ = this.calculatorQuery.selectIntake();
        this.clipboardContent$ = this.calculatorQuery.selectClipboardResult().pipe(map((results) => results.join('\n')));
        
        this.graphTabs$ = combineLatest([this.site$, this.calculatorQuery.selectActive()]).pipe(
            map(([siteConfig, calculator]) => {
                const riskKeys = siteConfig === Site.LifeHf ? LIFEHF_RISK_KEYS : RISK_KEYS;

                return riskKeys
                    .filter((risk) => Object.keys(calculator.risks).includes(risk))
                    .map((key) => {
                        const label = siteConfig === Site.LifeHf ? key + '_HF' : key;

                        return ({
                            key: key,
                            label: label,
                            selected: calculator.selectedGraph === key,
                        });
                    });
            })
        );        

        combineLatest([
            this.generalQuery.selectSite(),
            this.translateService.onLangChange
        ]).pipe(
            untilDestroyed(this)
        ).subscribe(([site, ]) => {
            this.setLabels(site);
        });
        this.setLabels(this.generalQuery.getSiteConfiguration().site);
        
        this.calculatorQuery
            .selectFormFields()
            .pipe(
                tap(fields => {
                    this.treatmentFields = this.calculatorService.createTreatmentForm(fields).map((fieldGroup) => {
                        if (fieldGroup.key !== 'treatment') {
                            fieldGroup.hide = true;
                        }
                        
                        return fieldGroup;
                    });

                    return (this.treatmentFields[0] || {}).fieldGroup;
                }),
                tap((treatmentFields) => {
                    const flatModel = this.calculatorService.getPostData();
                    const treatment: PatientFieldCategories = {};
                    
                    if (treatmentFields) {
                        treatmentFields
                            .filter((field) => field.id.endsWith(SUFFIX))
                            .forEach((field) => {
                                const intakeFieldName = field.id.substr(0, field.id.length - SUFFIX.length);
                                const intakeValue = flatModel[intakeFieldName];
                                if (intakeValue) {
                                    treatment[`${field.id}Group`] = {
                                        [`${field.id}Field`]: {
                                            [field.id]: (typeof intakeValue === 'object' && 'value' in intakeValue) ? intakeValue.value : intakeValue,
                                        },
                                    };
                                }
                            });
                    }

                    this.treatmentModel = {
                        ...JSON.parse(JSON.stringify(this.calculatorQuery.getActive().model)),
                        treatment
                    };
                })
            ).subscribe().unsubscribe();

        this.isDesktop = this.deviceDetectorService.isDesktop();

        if (this.parentUrl != null) {
            this.endSessionTrigger.pipe(
                withLatestFrom(this.patientData$, this.calculator$),
                untilDestroyed(this)
            ).subscribe(([, patientData, calculator]) => {
                const session = this.toSession(this.calculatorService.getPostData(), patientData, calculator);
    
                window.parent.postMessage({ session }, this.parentUrl);
            });
    
            window.addEventListener('message', this.sessionMessageHandler);

            combineLatest([this.calculator$, this.loading$, this.error$]).pipe(
                map(([calculator, loading, error]) => calculator !== null && !loading && error === null),
                debounceTime(1000),
                untilDestroyed(this)
            ).subscribe(x => window.parent.postMessage({ canEndSession: x }, this.parentUrl));
        }

        this.subjectRecalculate.pipe(
            debounceTime(1500),
            switchMap(data => this.calculatorService.getTreatmentResult(data)),
            untilDestroyed(this)
        ).subscribe();
    }

    public ngOnDestroy(): void {
        const url = this.router.url;
        if (!url.includes('calculators')) {
            this.calculatorService.setActiveCalculator('');
        }

        if (this.userQuery.getUserType() === UserType.Connect) {
            window.parent.postMessage({ canEndSession: false }, this.parentUrl);
            window.removeEventListener('message', this.sessionMessageHandler);
        }
    }

    public openModal(): void {
        const input: DialogConfig = {
            announcementText: 'LANGUAGE_NOT_SUPPORTED.ANNOUNCEMENT',
            bodyText: 'LANGUAGE_NOT_SUPPORTED.BODY',
            agreeBtn: {
                text: 'LANGUAGE_NOT_SUPPORTED.AGREE',
                type: ButtonType.Positive
            },
            site: this.generalQuery.getSiteConfiguration().site
        };

        const modalRef = this.dialogService.openAnnouncementModal(input);

        this.dialogService.awaitResponse(modalRef).subscribe();
    }

    public updateResult(data: PatientModel): void {
        this.subjectRecalculate.next(data);
    }

    public reopenIntake(): void {
        this.router.navigate(['calculators', this.calculatorName], {
            queryParams: { isAdjustIntake: true },
        });
    }

    public print(): void {
        this.prepareForPrint = true;
        setTimeout(() => {
            window.print();
            this.prepareForPrint = false;
        }, 100);
    }

    public confirmCopiedToClipboard(): void {
        this.copySuccessMessage = {
            text: this.translateService.instant('RESULTS.COPIED_TO_CLIPBOARD'),
        };
    }

    public selectTab(key: string): void {
        this.calculatorService.updateSelectedGraph(key);
    }

    public hideGraph(hide: boolean): boolean {
        return hide && !this.prepareForPrint;
    }

    private readonly sessionMessageHandler = (event: MessageEvent): void => {
        if (event.origin === this.parentUrl) {
            if (event.data.endSession !== undefined) {
                this.endSessionTrigger.next(true);
            }
        }    
    };

    private setLabels(site: Site): void {
        this.tenYearLabels = {};
        this.tenYearLabels.title = this.translateService.instant(TEN_YEARS_TITLE_KEY);
        this.tenYearLabels.percentage = this.translateService.instant(PERCENTAGE_KEY);
        this.tenYearLabels.ages = this.translateService.instant(AGES_KEY);
        this.tenYearLabels.current = this.translateService.instant(CURRENT_KEY);
        this.tenYearLabels.future = this.translateService.instant(FUTURE_KEY);

        this.twoYearLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(site === Site.LifeHf ? TWO_YEARS_TITLE_HF_KEY : TWO_YEARS_TITLE_KEY),
        };

        this.fiveYearLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(site === Site.LifeHf ? FIVE_YEARS_TITLE_HF_KEY : FIVE_YEARS_TITLE_KEY),
        };

        this.twoYearSecondaryLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(site === Site.LifeHf ? TWO_YEARS_SECONDARY_TITLE_HF_KEY : TWO_YEARS_SECONDARY_TITLE_KEY),
        };

        this.fiveYearSecondaryLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(site === Site.LifeHf ? FIVE_YEARS_SECONDARY_TITLE_HF_KEY : FIVE_YEARS_SECONDARY_TITLE_KEY),
        };

        this.fiveYearWithHeartFailureLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(FIVE_YEARS_HEARTFAILURE_TITLE_KEY),
        };

        this.tenYearWithHeartFailureLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(TEN_YEARS_HEARTFAILURE_TITLE_KEY),
        };

        this.lifetimeLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(LIFETIME_TITLE_KEY),
        };

        this.expectancyLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(site === Site.LifeHf ? EXPECTANCY_TITLE_HF_KEY : EXPECTANCY_TITLE_KEY),
        };

        this.expectancySecondaryLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(site === Site.LifeHf ? EXPECTANCY_SECONDARY_TITLE_HF_KEY : EXPECTANCY_SECONDARY_TITLE_KEY),
        };

        this.diseaseRiskLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(DISEASE_TITLE_KEY),
        };

        this.mortalityRiskLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(MORTALITY_TITLE_KEY),
        };
        this.oneYearVteRecurrenceLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(ONE_YEAR_VTE_RECURRENCE_TITLE_KEY)
        };
        this.oneYearBleedingLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(ONE_YEAR_BLEEDING_TITLE_KEY)
        };
        this.fiveYearVteRecurrenceLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(FIVE_YEARS_VTE_RECURRENCE_TITLE_KEY)
        };
        this.fiveYearBleedingLabels = {
            ...this.tenYearLabels,
            title: this.translateService.instant(FIVE_YEARS_BLEEDING_TITLE_KEY)
        };
    }

    private toSession(patient: ExportPatientData, patientData: PatientData, calculator: Calculator): Session {
        return {
            id: calculator.id,
            version: calculator.version,
            patient: this.toSessionPatient(patient, this.toPatientDataFields(patientData)),
            imputations: calculator.imputations ?? [],
            futureTreatment: this.toSessionFutureTreatment(calculator),
            twoYearRisk: calculator.risks.twoYearRisk,
            fiveYearRisk: calculator.risks.fiveYearRisk,
            twoYearRiskSecondary: calculator.risks.twoYearRiskSecondary,
            fiveYearRiskSecondary: calculator.risks.fiveYearRiskSecondary,
            fiveYearRiskWithHeartFailure: calculator.risks.fiveYearRiskWithHeartFailure,
            tenYearRisk: calculator.risks.tenYearRisk,
            tenYearRiskWithHeartFailure: calculator.risks.tenYearRiskWithHeartFailure,
            lifetimeRisk: calculator.risks.lifetimeRisk,
            mortalityRisk: calculator.risks.mortalityRisk,
            diseaseRisk: calculator.risks.diseaseRisk,
            lifeExpectancy: calculator.risks.lifeExpectancy,
            lifeExpectancySecondary: calculator.risks.lifeExpectancySecondary
        };
    }

    private toSessionPatient(patient: ExportPatientData, patientDataFields: Record<string, PatientDataField>): {
        [key: string]: { 
            value: Value | null; 
            timestamp: Date;
            unit?: string;
        };
    } {
        return Object.keys(patient)
            .filter(key => patient[key] != null)
            .map(key => {
                const patientField = patient[key];
                const patientDataField = patientDataFields[key];
                
                return {
                    key,
                    timestamp: patientDataField != null && patientDataField.overriddenValue === undefined && patientDataField.timestamp !== undefined
                        ? new Date(Date.parse(patientDataField.timestamp)) 
                        : new Date(),
                    unit: (typeof patientField === 'object' && 'unit' in patientField) ? patientField.unit : undefined,
                    value: (typeof patientField === 'object' && 'value' in patientField) ? patientField.value : patientField
                };
            })
            .filter(x => x.value !== 'Imputed')
            .map(x => Array.isArray(x.value)
                ? x.value.map(y => ({
                    key: `${y}`,
                    timestamp: x.timestamp,
                    value: true,
                    unit: x.unit
                }))
                : [x])
            .reduce((sum, add) => ([ ...sum, ...add ]), [])
            .filter(x => x.value != null)
            .map(x => ({[x.key]: {
                timestamp: x.timestamp,
                unit: x.unit,
                value: x.value
            }}))
            .reduce((sum, add) => ({ ...sum, ...add }), {});
    }

    private toSessionFutureTreatment(calculator: Calculator): Record<string, Value> {
        return calculator.fields
            .filter(x => x.id.endsWith('Target') || x.id.endsWith('Future'))
            .map(field => {
                const category = `${field.category}${field.unitSelector || ''}`;

                const fieldGroup = ((calculator.model[category] || {})[`${field.id}Group`] || {})[`${field.id}Field`];
                let value = typeof fieldGroup !== 'boolean' ? fieldGroup[field.id] : null;
                
                if (value === 'null') {
                    value = null;
                }

                return { [field.id]: value };
            })
            .reduce((sum, add) => ({ ...sum, ...add }), {});
    }

    private toPatientDataFields(patientData: PatientData): Record<string, PatientDataField> {
        const dataForCurrentCalculator = getDataForCalculator(patientData, this.calculatorName) ?? {};
        const dataForCurrentCalculatorKeys = Object.keys(dataForCurrentCalculator);

        return patientData.dataForCalculator
            .filter(x => x.calculatorId !== this.calculatorName)
            .reduce((sum, add) => ({ ...sum, ...Object
                .entries(add.data)
                .filter(x => !dataForCurrentCalculatorKeys.includes(x[0]))
                .reduce((sum2, add2) => ({...sum2, ...{ [add2[0]]: add2[1]}}), {})}), dataForCurrentCalculator);
    }
}